.creep {
  color: white;
}

.creep-name {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: red;
}

.creep-image-attributes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.creep-image {
  height: 12rem;
  width: 12rem;
  padding: 1rem;
}

.creep-image-mobile {
  height: 8rem;
  width: 8rem;
  padding: 1rem;
}