.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  background-color: red;
  color: white;
  font-size: 1.5rem;
  padding: 1rem;
  height: 2rem;
  width: 6rem;
  margin: 0 auto;
  cursor: pointer;
}

.button-container:hover {
  background-color: white;
  color: red;
  border: 2px solid red;
}

.button-text {
  text-transform: uppercase;
}